<template>
  <a-modal
    width="700px"
    v-model:visible="visible"
    :centered="true"
    :maskClosable="false"
    :ok-button-props="{ hidden: true }"
    :cancelText="$t('common.close')"
    :title="title"
  >
    <a-input-search
      v-model:value="searchValue"
      :placeholder="$t('warehouse.please_enter_product_name_product_number_seSku')"
      :enter-button="$t('common.search')"
      class="mb-3"
      :loading="loading"
      @search="handleSearchProducts"
    />

    <a-table
      :columns="columns"
      :data-source="tableData"
      :scroll="{ x: 500, y: 600 }"
      :pagination="false"
      size="small"
      :rowKey="
        (record, index) => {
          return index;
        }
      "
      :loading="loading"
    >
      <template #productNameAndSeSku>
        <span>
          {{ $t("warehouse.product_name") }}/{{ $t("common.product_no") }}
        </span>
      </template>

      <template #productNameAndSeSkuCustom="{ record }">
        <a-space>
          <div class="table-list-img-common">
            <c-image :src="record.imgUrl"></c-image>
          </div>
          <div>
            <div>{{ record.productName }}</div>
            <div>{{ record.productNo }}</div>
          </div>
        </a-space>
      </template>

      <template #addButtonCustom="{ record }">
        <div class="ant-form-inline">
          <a-button
            @click="handleAddProductToList(record)"
            type="primary"
            ghost
          >
            <span>{{ $t("common.add") }}</span>
          </a-button>
        </div>
      </template>
    </a-table>
    <a-row type="flex" justify="space-around" align="middle" class="mt-3">
      <a-col>
        <CPager
          @handlePage="handlePage"
          :showLessItems="true"
          :pageSizeOptions="['5', '10', '30']"
          :page-data="pageData"
        ></CPager>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, toRefs } from "vue";
import { Modal, Input, Table, Space, Button, Row, Col } from 'ant-design-vue';
import CImage from '@/views/components/CImage';
import CPager from '@/views/components/CPager.vue';
import { getProductList } from "@/api/modules/product/index";

export default defineComponent({
  components: {
    AModal: Modal,
    AInputSearch: Input.Search,
    ATable: Table,
    ASpace: Space,
    AButton: Button,
    ARow: Row,
    ACol: Col,
    CPager,
    CImage,
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      visible: false,
      loading: false,
      searchValue: null,
      tableData: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 5
      },
    });

    const columns = [
      {
        width: 300,
        slots: {
          title: "productNameAndSeSku",
          customRender: "productNameAndSeSkuCustom",
        },
      },
       {
        title: 'SESKU',
        dataIndex: "seSku",
        width: 100,
      },
      {
        title: "",
        dataIndex: "addButton",
        slots: {
          customRender: "addButtonCustom",
        },
        width: 150,
      }
    ]

    const handleGetList = () => {
      state.loading = true
      const data = Object.assign({}, state.pageData, {
        searchKey: state.searchValue,
        isPackingVerify: true
      })
      getProductList(data).then(({ result }) => {
        let { items = [], totalCount = 0 } = result
        state.pageData.totalCount = parseInt(totalCount);
        state.tableData = items;
      }).finally(() => {
        state.loading = false
      })
    }

    const handlePage = (pageData) => {
      state.pageData.skipCount = pageData.skipCount;
      state.pageData.maxResultCount = pageData.maxResultCount;
      handleGetList();
    }

    const handleSearchProducts = () => {
      state.pageData.skipCount = 0;
      state.pageData.currentIndex = 1;
      handleGetList();
    }

    const handleAddProductToList = (record) => {
      const product = Object.assign({}, record)
      product.productId = record.id
      product.fnSku = null
      product.count = null
      emit('confirm', product)
      // state.visible = false
    }

    const init = () => {
      state.visible = true
      nextTick(() => {
        handleSearchProducts()
      })
    }

    return {
      ...toRefs(state),
      columns,
      handleSearchProducts,
      handleAddProductToList,
      handlePage,
      init,
    };
  },
});
</script>
