<template>
  <Content>
    <template #contentTitle>{{
      $t("menu.consignment_return_channel_create")
    }}</template>
    <template #contentBody>
      <a-form
        v-if="pageStep === 0"
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="{ style: { minWidth: '120px' } }"
      >
        <a-form-item
          :label="$t('warehouse.delivery_way')"
          :wrapper-col="{ span: 6 }"
          name="deliveryType"
        >
          <a-select
            style="min-width: 200px"
            v-model:value="formState.deliveryType"
            :placeholder="$t('common.please_select')"
            :show-search="true"
            option-filter-prop="search-key"
            @change="formState.packageNo = ''"
          >
            <a-select-option
              v-for="(item, index) in deliveryWayEnumList"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('deliveryWay', item))"
              :search-key="$t($enumLangkey('deliveryWay', item))"
              >{{ $t($enumLangkey("deliveryWay", item)) }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          :label="$t('warehouse.return_the_parcel')"
          :wrapper-col="{ span: 6 }"
          name="packageNo"
        >
          <a-input
            style="min-width: 200px"
            v-model:value="formState.packageNo"
            :placeholder="
              $t('warehouse.parcel_tracking_number') +
              $t('warehouse.scannable_number')
            "
          />
        </a-form-item>
        <a-form-item
          :label="$t('warehouse.standby_identification1')"
          :wrapper-col="{ span: 6 }"
          name="packageNo1"
        >
          <a-input
            style="min-width: 200px"
            v-model:value="formState.packageNo1"
            :placeholder="
              $t('warehouse.parcel_tracking_number') +
              $t('warehouse.scannable_number')+
              '('+$t('common.optional')+')'
            "
          />
        </a-form-item>
        <a-form-item
          :label="$t('warehouse.standby_identification2')"
          :wrapper-col="{ span: 6 }"
          name="packageNo2"
        >
          <a-input
            style="min-width: 200px"
            v-model:value="formState.packageNo2"
            :placeholder="
              $t('warehouse.parcel_tracking_number') +
              $t('warehouse.scannable_number')+
              '('+$t('common.optional')+')'
            "
          />
        </a-form-item>
        <a-form-item
          :label="$t('warehouse.the_goods_warehouse')"
          :wrapper-col="{ span: 6 }"
          name="receiveWarehouseId"
        >
          <a-select
            style="min-width: 200px"
            v-model:value="formState.receiveWarehouseId"
            :placeholder="$t('common.please_select')"
            :show-search="true"
            option-filter-prop="search-key"
            @change="handleWarehouseChanged"
          >
            <a-select-option
              v-for="(item, index) in warehouseList"
              :key="index"
              :value="item.id"
              :title="`${item.warehouseNo}(${item.warehouseName})`"
              :search-key="item.warehouseNo + item.warehouseName"
              >{{ item.warehouseNo }}({{ item.warehouseName }})</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('warehouse.warehouse_address')">
          <a-space v-if="warehouseInfo">
            <span>{{ getAddressInfo() }}</span>
            <span class="ml-5" v-if="warehouseInfo.rtLinkMan && warehouseInfo.rtLinkManPhone">
              {{ $t("logistics.return_contact_name") }}: 
              {{ warehouseInfo.rtLinkMan }},
              {{ warehouseInfo.phoneCountryCode ? warehouseInfo.phoneCountryCode + '-' : '' }}{{ warehouseInfo.rtLinkManPhone }}
            </span>
            <span class="ml-5" v-else>
              {{ $t("logistics.contacts") }}: {{ getLinkman(warehouseInfo) }}
            </span>
          </a-space>
        </a-form-item>
        <a-form-item
          :label="$t('warehouse.return_of_the_source')"
          :wrapper-col="{ span: 6 }"
          name="sourceType"
        >
          <a-select
            style="min-width: 200px"
            v-model:value="formState.sourceType"
            :placeholder="$t('common.please_select')"
          >
            <a-select-option
              v-for="(item, index) in returnPlanSourceTypeList"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('returnPlanSourceType', item))"
              >{{
                $t($enumLangkey("returnPlanSourceType", item))
              }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button @click="handleShowModal">{{
            $t("warehouse.adding_returned_products")
          }}</a-button>
        </a-form-item>
      </a-form>
      <template v-else>
        <a-row class="mt-2" :gutter="16">
          <a-col>{{ $t("warehouse.delivery_way") }}: </a-col>
          <a-col>{{
            $t($enumLangkey("deliveryWay", formState.deliveryType))
          }}</a-col>
        </a-row>
        <a-row  class="mt-2" :gutter="16" type="flex" align="middle">
          <a-col>{{ $t("warehouse.return_the_parcel") }}: </a-col>
          <a-col>{{ formState.packageNo }}</a-col>
          <a-col class="ml-5">{{ $t("warehouse.standby_identification1") }}: </a-col>
          <a-col>{{ formState?.packageNo1??"-" }}</a-col>
          <a-col class="ml-5">{{ $t("warehouse.standby_identification2") }}: </a-col>
          <a-col>{{ formState?.packageNo2??"-" }}</a-col>
        </a-row>
        <a-row class="mt-2" :gutter="16">
          <a-col>{{ $t("warehouse.the_goods_warehouse") }}: </a-col>
          <a-col>{{ getWarehouseName(formState.receiveWarehouseId) }}</a-col>
        </a-row>
        <a-row class="mt-2" :gutter="16">
          <a-col>{{ $t("warehouse.warehouse_address") }}: </a-col>
          <a-col class="mr-3">
            <a-space>
              <span>{{ getAddressInfo() }}</span>
              <span class="ml-5" v-if="warehouseInfo.rtLinkMan && warehouseInfo.rtLinkManPhone">
                {{ $t("logistics.return_contact_name") }}: 
                {{ warehouseInfo.rtLinkMan }},
                {{ warehouseInfo.phoneCountryCode ? warehouseInfo.phoneCountryCode + '-' : '' }}{{ warehouseInfo.rtLinkManPhone }}
              </span>
              <span class="ml-5" v-else>
                {{ $t("logistics.contacts") }}: {{ getLinkman(warehouseInfo) }}
              </span>
            </a-space>
          </a-col>
        </a-row>
        <a-row class="mt-2" :gutter="16">
          <a-col>{{ $t("warehouse.return_of_the_source") }}: </a-col>
          <a-col>{{
            $t($enumLangkey("returnPlanSourceType", formState.sourceType))
          }}</a-col>
        </a-row>
      </template>
      <a-table
        class="mt-3"
        :columns="columns"
        size="small"
        :data-source="productList"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="loading"
        :pagination="{
          defaultPageSize: defaultPageSize,
          hideOnSinglePage: true,
          current: currentPageIndex,
          position: 'both',
        }"
        :scroll="{ x: 500, y: false }"
        @change="
          (page) => {
            currentPageIndex = page.current;
          }
        "
      >
        <template #serialNumber="{ index }">{{
          defaultPageSize * (currentPageIndex - 1) + (index + 1)
        }}</template>
        <template #productInfo="{ record }">
          <a-space>
            <div class="table-list-img-common">
              <c-image :src="record.imgUrl" />
            </div>
            <div>
              <div>{{ record.productName }}</div>
              <div>{{ record.productNo }}</div>
            </div>
          </a-space>
        </template>
        <template #packingImgUrl="{ record }">
          <div class="table-list-img-common" v-if="record.packingImgUrl">
            <c-image :src="record.packingImgUrl" />
          </div>
          <span v-else>-</span>
        </template>
        <template #packagingSize="{ record }">
          {{ record.packingLength }}x{{ record.packingWidth }}x{{
            record.packingHeight
          }}cm
        </template>
        <template #productWeight="{ record }">
          <span v-if="record.packingVerifyInfo"
            >{{ handleGToKg(record.packingVerifyInfo.packingWeight) }}kg</span
          >
          <span v-else>{{ handleGToKg(record.packingWeight) }}kg</span>
        </template>
        <template #packingVerifyInfo="{ record }">
          <span v-if="record.packingVerifyInfo"
            >{{ record.packingVerifyInfo.packingLength }}x{{
              record.packingVerifyInfo.packingWidth
            }}x{{ record.packingVerifyInfo.packingHeight }}cm/{{
              handleGToKg(record.packingVerifyInfo.packingWeight)
            }}kg</span
          >
          <span v-else>{{ $t("warehouse.no_approved_record") }}</span>
        </template>
        <template #isKeepProductPackageTitle>
          <a-select
            v-if="pageStep === 0"
            style="width: 170px"
            v-model:value="isKeepAllProductPackage"
            :disabled="productList.length === 0"
            :placeholder="$t('warehouse.whether_to_keep_the_outer_packaging')"
            @change="handleChangeProductPackage"
          >
            <a-select-option
              v-for="(item, index) in outerPackagingStatusList"
              :key="index"
              :value="item.value"
            >{{ $t(item.label) }}</a-select-option>
          </a-select>
          <span v-else>{{ $t('warehouse.whether_to_keep_the_outer_packaging') }}</span>
        </template>
        <template #isKeepProductPackage="{ record }">
          <a-select
            v-if="pageStep === 0"
            style="width: 170px"
            v-model:value="record.isKeepProductPackage"
            :placeholder="$t('warehouse.whether_to_keep_the_outer_packaging')"
            @change="handleTableOuterPackagingStatusChange(record)"
          >
            <a-select-option
              v-for="(item, index) in outerPackagingStatusList"
              :key="index"
              :value="item.value"
            >{{ $t(item.label) }}</a-select-option>
          </a-select>
          <template v-else>
            <span v-if="record.isKeepProductPackage === 1">{{ $t("warehouse.need_to_keep_the_outer_packaging") }}</span>
            <span v-else-if="record.isKeepProductPackage === 0">{{ $t("warehouse.do_not_keep_the_outer_packaging") }}</span>
            <span v-else>-</span>
          </template>
        </template>
        <template #optionalServices="{ record }">
          <div v-if="pageStep === 0">
            <div v-if="record.qualityControlFee">
              <a-checkbox
                v-model:checked="record.qualityControlFee.isActivited"
                @change="handleQualityControlFeeChange(record)"
              >
                {{
                  $t(
                    $enumLangkey(
                      "orderLogisticsFee",
                      record.qualityControlFee.feeType
                    )
                  )
                }}
              </a-checkbox>
              {{ record.qualityControlFee.currencySymbol
              }}{{ $filters.amountToFixed2(record.qualityControlFee.price) }}
            </div>
            <div v-else>-</div>
            <template
              v-if="
                record.qualityControlFee && record.qualityControlFee.isActivited
              "
            >
              <div v-if="record.functionQualityControlFee">
                <a-checkbox
                  v-model:checked="record.functionQualityControlFee.isActivited"
                >
                  {{
                    $t(
                      $enumLangkey(
                        "orderLogisticsFee",
                        record.functionQualityControlFee.feeType
                      )
                    )
                  }}
                </a-checkbox>
                {{ record.functionQualityControlFee.currencySymbol
                }}{{
                  $filters.amountToFixed2(
                    record.functionQualityControlFee.price
                  )
                }}
              </div>
            </template>
          </div>
          <div v-else>
            <div
              v-if="
                record.qualityControlFee && record.qualityControlFee.isActivited
              "
            >
              {{
                $t(
                  $enumLangkey(
                    "orderLogisticsFee",
                    record.qualityControlFee.feeType
                  )
                )
              }}
            </div>
            <div
              v-if="
                record.functionQualityControlFee &&
                record.functionQualityControlFee.isActivited
              "
            >
              {{
                $t(
                  $enumLangkey(
                    "orderLogisticsFee",
                    record.functionQualityControlFee.feeType
                  )
                )
              }}
            </div>
            <template
              v-if="
                record.qualityControlFee &&
                !record.qualityControlFee.isActivited
              "
            >
              <div
                v-if="
                  record.functionQualityControlFee &&
                  !record.functionQualityControlFee.isActivited
                "
              >
                -
              </div>
            </template>
          </div>
        </template>
        <template #uniqueInformation>
          <a-select
            v-if="pageStep === 0"
            style="width: 170px"
            v-model:value="formState.fnSkuType"
            :placeholder="$t('common.please_select')"
          >
            <a-select-option
              v-for="(item, index) in uniqueInformationList"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('returnPlanFnskuType', item))"
              :disabled="disabledArtificialPerception(item)"
            >
              {{ $t($enumLangkey("returnPlanFnskuType", item)) }}
            </a-select-option>
          </a-select>
          <span v-else>{{
            $t($enumLangkey("returnPlanFnskuType", formState.fnSkuType))
          }}</span>
        </template>
        <template #fnSku="{ record }">
          <template v-if="pageStep === 0">
            <a-input
              v-model:value="record.fnSku"
              style="width: 170px"
              :placeholder="$t('warehouse.FNSKU_and_other_unique_information')"
              @blur="handlefnSkuChange(record)"
              allowClear
            />
            <div class="mt-1" v-if="record?.artificialPerceptionFee && formState.fnSkuType === returnPlanFnskuTypeEnum.artificialPerception">
              {{ $t($enumLangkey("orderLogisticsFee", orderLogisticsFeeEnum.ArtificialPerception)) }}: 
              {{ record.artificialPerceptionFee?.currencySymbol }}{{ $filters.amountToFixed2(record.artificialPerceptionFee?.price) }}
            </div>
          </template>
          <span v-else>{{ record.fnSku }}</span>
        </template>
        <template #count="{ record }">
          <a-input-number
            v-if="pageStep === 0"
            v-model:value="record.count"
            :min="1"
            :step="1.0"
            :parser="(value) => parseInt(value)"
          />
          <span v-else>{{ record.count }}</span>
        </template>
        <template #operation="{ index }">
          <a-button
            v-if="pageStep === 0"
            size="small"
            @click="handleRemoveProduct(index)"
            >{{ $t("common.remove") }}</a-button
          >
        </template>
        <template #footer v-if="pageStep !== 0">
          <a-row :gutter="32" type="flex" justify="end">
            <a-col
              >{{ $t("warehouse.product_SKU") }}:
              {{ productList.length }}</a-col
            >
            <a-col
              >{{ $t("warehouse.returns_the_total") }}:
              {{ getProductCount }}</a-col
            >
          </a-row>
        </template>
      </a-table>
      <template v-if="pageStep === 0">
        <div class="mt-2" v-if="productList.length">
          <span v-if="useQualityControlFee()">{{
            $t("warehouse.destruction_of_prompt_p0", [getDestructionFee])
          }}</span>
        </div>
      </template>
      <a-row v-else class="mt-3" type="flex" justify="space-between">
        <a-col>
          <div class="mt-2">
            <div v-if="useQualityControlFee()">
              <a-checkbox
                v-model:checked="pageConfirmState.destroyServiceFee"
                >{{ $t("warehouse.agree_to_destroy_service_fee") }}</a-checkbox
              >
            </div>
            <div>
              <a-checkbox
                v-model:checked="pageConfirmState.differentialCharges"
                >{{ $t("warehouse.agree_to_differential_charges") }}</a-checkbox
              >
            </div>
          </div>
        </a-col>
        <a-col v-if="collectFees">
          <a-form
            :label-col="{ style: { width: '250px' } }"
            :wrapper-col="{ style: { width: '100px' } }"
          >
            <template v-for="(item, index) in collectFees.feeList" :key="index">
              <a-form-item
                v-if="item.price"
                style="margin-bottom: 0"
                :label="$t($enumLangkey('orderLogisticsFee', item.feeType))"
                >{{ item.currencySymbol
                }}{{ $filters.amountToFixed2(item.amount) }}</a-form-item
              >
            </template>
            <a-divider style="margin: 0" />
            <a-form-item
              style="margin-bottom: 0"
              :label="$t('warehouse.total_prices')"
              >{{ collectFees.currencySymbol
              }}{{ $filters.amountToFixed2(collectFees.amount) }}</a-form-item
            >
          </a-form>
        </a-col>
      </a-row>
      <a-row type="flex" justify="end" class="mt-3 mb-5">
        <a-col>
          <a-button
            v-if="pageStep === 0"
            :loading="btnLoading"
            type="primary"
            @click="handleNextStep"
            >{{ $t("common.next_step") }}</a-button
          >
          <a-space v-else>
            <a-button :disabled="btnLoading" @click="handleBack">{{
              $t("common.back_step")
            }}</a-button>
            <a-button
              :loading="btnLoading"
              :disabled="disabledConfirmBtn"
              type="primary"
              @click="handleConfirm"
              >{{ $t("warehouse.confirm_and_pay") }}</a-button
            >
          </a-space>
        </a-col>
      </a-row>
      <ChooseProductModal
        ref="chooseProductModalRef"
        :title="$t('warehouse.adding_returned_products')"
        @confirm="handleAddProduct"
      />
      <Payment
        ref="refPayment"
        :currencyId="collectFees?.currencyId ?? ''"
        :unpaidSymbol="collectFees?.currencySymbol ?? ''"
        :unpaidSum="collectFees.amount ?? 0"
        @handlePayFee="handleOrderPay"
        :loading="payModal.loading"
        v-model:visible="payModal.visible"
      ></Payment>




       <!-- 支付完后包裹存在于仓库中的 消息提示 -->
      <a-modal v-model:visible="returnGoodHintModal.visible"
               :confirm-loading="returnGoodHintModal.loading"
               :maskClosable="false"
               @cancel="handleCancelPlan"
               :cancelText="$t('common.cancel')"
               :okText="$t('common.confirm')"
               :footer="null"
               :centered="true">
        <div class="p-3">
          <div  class="text-center">
             {{$t('warehouse.return_package_number')}}: <strong>{{returnGoodHintModal.packageNo}}</strong>
          </div>
          <a-row type="flex"
                 class="mt-2">
            <a-col :span="4">
                <InfoCircleOutlined class="text-warning" :style="{fontSize: '48px'}"/> 
            </a-col>
            <a-col :span="19">
              <span class="text-warning">
                {{$t("warehouse.received_by_warehouse_at_p0_has_been_stored_for_p1_days", [$filters.utcToCurrentTime(returnGoodHintModal.creationTime),returnGoodHintModal.days])}}
                <!-- 在2022-11-02 15:32 由仓库接收，已存放14天 -->
                </span>
              <div >
                {{$t("warehouse.the_return_hold_fee_of_p0_will_be_automatically_deducted_by_the_system_and_can_be_viewed_in_the_return_plan_details", [(returnGoodHintModal.currencySymbol+$filters.amountToFixed2(returnGoodHintModal.amount))])}}
                <!-- 退货暂存费  $8.00美金将由系统自动扣除，
                并在退货计划详情中可查看该费用 -->
              </div>
            </a-col>
          </a-row>

          <a-row type="flex"
                 justify="center"
                 align="middle"
                 class="mt-3">
            <a-col >
              <a-button type="primary"
                        @click="handleCancelPlan">{{$t('common.confirm')}}</a-button>
            </a-col>
          </a-row>
        </div>
      </a-modal>

       <!-- 创建之前必阅读 消息提示 -->
      <a-modal v-model:visible="beforeCreationHintModalVisible"
               :maskClosable="false"
               @cancel="handleCancelPlan"
               :footer="null"
               :centered="true">
               <a-spin  :spinning="(destroyRtsTemporaryDays?false:true)">
        <div class="p-3">
          <div >
             <strong>{{$t('warehouse.please_create_a_return_plan_before_the_return_arrives_at_warehouse')}}</strong>
          </div>
          
          <div class="mt-3">
            <span>{{$t('common.cautions')}}:</span>
            <div class="ml-3">1. {{$t('warehouse.tips_for_creating_a_return_plan_1')}} </div>
            <div class="ml-3">2. {{$t('warehouse.tips_for_creating_a_return_plan_2',[destroyRtsTemporaryDays])}}</div>
          </div>

          <a-row type="flex"
                 justify="center"
                 align="middle"
                 class="mt-3">
            <a-col >
              <a-button type="primary"
                        @click="beforeCreationHintModalVisible=false">{{$t('common.i_already_know')}}</a-button>
            </a-col>
          </a-row>
        </div>
    </a-spin >

      </a-modal>

      <!-- 创建的包裹是否被销毁 消息提示 -->
      <a-modal v-model:visible="destroyModal.visible"
               :confirm-loading="destroyModal.loading"
               :maskClosable="false"
               :footer="null"
               @cancel="handleCancelPlan"
               :centered="true">
        <div class="p-3">
        
          <a-row type="flex"
                 class="mt-2">
            <a-col :span="4">
                <InfoCircleOutlined class="text-error" :style="{fontSize: '48px'}"/> 
            </a-col>
            <a-col :span="19">
              <span class="text-error">
                {{$t("warehouse.the_goods_with_package_number_p0_have_been_destroyed_time_of_entry_p1_time_of_destruction_p3", [destroyModal.packageNo,$filters.utcToCurrentTime(destroyModal.creationTime),$filters.utcToCurrentTime(destroyModal.destroyTime)])}}
                </span>
            </a-col>
          </a-row>

          <a-row type="flex"
                 justify="center"
                 align="middle"
                 class="mt-3">
            <a-col >
              <a-button type="primary"
                        @click="handleCancelPlan">{{$t('common.confirm')}}</a-button>
            </a-col>
          </a-row>
        </div>
      </a-modal>

    </template>
  </Content>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onMounted,
  computed,
  nextTick,
  watch,
} from "vue";
import Content from "@/views/components/Content.vue";
import CImage from "@/views/components/CImage";
import Payment from "@/views/components/Payment.vue";
import ChooseProductModal from "./components/ChooseProductModal.vue";
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Table,
  Checkbox,
  Space,
  Form,
  Divider,
  InputNumber,
  message,Modal,spin
} from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import { getWarehouses } from "@/api/modules/common";
import {
  getServiceFeeConfig,
  getChannelReturnPlanFee,
  createChannelReturnPlan,
  checkPackage,
  isDestroyPackage,getRtsConfig
} from "@/api/modules/consignment/returnChannel";
import { getReturnTemporaryStorageById } from "@/api/modules/consignment/index.js";
import {
  returnPlanSourceType as returnPlanSourceTypeEnum,
  deliveryWay as deliveryWayEnum,
  warehouseType as warehouseTypeEnum,
  orderLogisticsFee as orderLogisticsFeeEnum,
  returnPlanFnskuType as returnPlanFnskuTypeEnum,
} from "@/enum/enum.json";
import { gToKg, getAddressByLanguageV2, roundNumber, getLinkman } from "@/utils/general";
import { isShipmentNoValid } from "@/utils/regexpValidator";
import { useStore } from "vuex";
import { useTab } from "@/hooks/tabs/index";
import { useRouter, useRoute } from "vue-router";
import filters from "@/filters";
export default defineComponent({
  name: "consignment_return_channel_create",
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AInputNumber: InputNumber,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ATable: Table,
    ACheckbox: Checkbox,
    ASpace: Space,
    AForm: Form,
    AFormItem: Form.Item,
    ADivider: Divider,
    Content,
    CImage,
    Payment,
    ChooseProductModal,
    AModal: Modal,
ASpin:spin
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const route = useRoute();
    const { getters } = useStore();
    const { delVisitedRoute } = useTab();

    const defaultPageSize = ref(50);
    const currentPageIndex = ref(1);
    const formRef = ref(null);
    const chooseProductModalRef = ref(null);
    const refPayment = ref(null);

    const state = reactive({
      beforeCreationHintModalVisible:false,
      destroyRtsTemporaryDays:null,//消息提示中被销毁的天数
      loading: false,
      btnLoading: false,
      pageStep: 0,
      warehouseList: [],
      deliveryWayEnumList: [],
      returnPlanSourceTypeList: [],
      formState: {
        // 商业平台
        sourceType: null,
        // 退货包裹编号
        packageNo: null,
        packageNo1: null,
        packageNo2: null,
        // 收货仓库
        receiveWarehouseId: null,
        // 派送方式
        deliveryType: null,
        // 退货计划可识别码类型
        fnSkuType: returnPlanFnskuTypeEnum.canScanInfo,
        returnPlanDetails: [],
      },
      warehouseInfo: null,
      productList: [],
      // 是否全选保留外包装
      isKeepAllProductPackage: null,
      // 产品唯一性选项
      uniqueInformationList: [],
      // 第二页的勾选内容
      pageConfirmState: {
        destroyServiceFee: false,
        differentialCharges: false,
      },
      // 支付时显示的费用
      collectFees: {
        amount: 0,
        currencyCode: null,
        currencyId: null,
        currencySymbol: null,
        isKeepProductPackage: null,
        feeList: [],
      },
      // 支付
      payModal: {
        visible: false,
        loading: false,
      },
    });

    
    const returnGoodHintModal = reactive({
      visible: false,
      loading: false,
      "packageNo": null,
      "creationTime": null,
      "days":null, 
      "currencySymbol": null,
      "amount": null
    });

    const destroyModal = reactive({
      visible: false,
      loading: false,
      "packageNo": null,
      "creationTime": null,
      "destroyTime":null, 
      "destroyDays": null,
      "destroyScopeDays": null
    });

    state.returnPlanSourceTypeList = Object.values(returnPlanSourceTypeEnum);
    state.deliveryWayEnumList = Object.values(deliveryWayEnum);
    state.uniqueInformationList = Object.values(returnPlanFnskuTypeEnum);

    const outerPackagingStatusList = [
      {
        label: 'warehouse.need_to_keep_the_outer_packaging',
        value: 1
      },
      {
        label: 'warehouse.do_not_keep_the_outer_packaging',
        value: 0
      },
    ]

    const validatePackageNo = async (rule, value) => {
      if (!value) {
        return Promise.reject(vueI18n.t("common.p0_is_required", [
          vueI18n.t("warehouse.return_the_parcel"),
        ]))
      } else {
        let sourceType = state.formState.deliveryType
        if (!isShipmentNoValid(sourceType, value)) {
          return Promise.reject(vueI18n.t("warehouse.please_enter_the_correct_p0", [
            vueI18n.t("warehouse.return_the_parcel"),
          ]))
        } else {
          return Promise.resolve();
        }
      }
    }

    const validatePackageNo1 = async (rule, value) => {
        if (state.formState.packageNo1) {
          if (state.formState.packageNo==state.formState.packageNo1
          ||
          state.formState.packageNo2==state.formState.packageNo1
          ) {
            return Promise.reject(vueI18n.t("warehouse.returned_package_and_alternate_identification_information_are_unique"))
          }
        } 

        if (value) {
          if (!isShipmentNoValid("standby_identification", value)) {
            return Promise.reject(vueI18n.t("warehouse.please_enter_the_correct_p0", [
              vueI18n.t("warehouse.return_the_parcel"),
            ]))
          } else {
            return Promise.resolve();
          }
        }
    }

     const validatePackageNo2 = async (rule, value) => {
        if (state.formState.packageNo2) {
          if (state.formState.packageNo==state.formState.packageNo2
          ||
          state.formState.packageNo2==state.formState.packageNo1
          ) {
            return Promise.reject(vueI18n.t("warehouse.returned_package_and_alternate_identification_information_are_unique"))
          }
        } 

        if (value) {
          if (!isShipmentNoValid("standby_identification", value)) {
            return Promise.reject(vueI18n.t("warehouse.please_enter_the_correct_p0", [
              vueI18n.t("warehouse.return_the_parcel"),
            ]))
          } else {
            return Promise.resolve();
          }
        }
    }

    const rules = {
      sourceType: {
        required: true,
        message: () =>
          vueI18n.t("common.p0_is_required", [
            vueI18n.t("warehouse.return_of_the_source"),
          ]),
      },
      packageNo: {
        required: true,
        validator: validatePackageNo,
        trigger: ['blur', 'change']
      },
      packageNo1: {
        validator: validatePackageNo1,
        trigger: ['blur', 'change']
      },
      packageNo2: {
        validator: validatePackageNo2,
        trigger: ['blur', 'change']
      },
      receiveWarehouseId: {
        required: true,
        message: () =>
          vueI18n.t("common.p0_is_required", [
            vueI18n.t("warehouse.the_goods_warehouse"),
          ]),
      },
      deliveryType: {
        required: true,
        message: () =>
          vueI18n.t("common.p0_is_required", [
            vueI18n.t("warehouse.delivery_way"),
          ]),
      },
    };

    const columns = [
      {
        width: 50,
        title: () => vueI18n.t("warehouse.serial_number"),
        slots: {
          customRender: "serialNumber",
        },
      },
      {
        width: 200,
        title: () =>
          vueI18n.t("warehouse.product_name") +
          "/" +
          vueI18n.t("common.product_no"),
        slots: {
          customRender: "productInfo",
        },
      },
      {
        title: 'SESKU',
        dataIndex: "seSku",
        width: 100,
      },
      {
        width: 80,
        title: () => vueI18n.t("logistics.packaging_figure"),
        slots: {
          customRender: "packingImgUrl",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("logistics.business_packaging_size"),
        slots: {
          customRender: "packagingSize",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.product_weight"),
        slots: {
          customRender: "productWeight",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.approved_packaging_info"),
        slots: {
          customRender: "packingVerifyInfo",
        },
      },
      {
        width: 200,
        slots: {
          title: "isKeepProductPackageTitle",
          customRender: "isKeepProductPackage",
        },
      },
      {
        width: 200,
        title: () => vueI18n.t("warehouse.optional_services"),
        slots: {
          customRender: "optionalServices",
        },
      },
      {
        width: 200,
        slots: {
          title: "uniqueInformation",
          customRender: "fnSku",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "count",
        },
      },
      {
        width: 80,
        fixed: "right",
        slots: {
          customRender: "operation",
        },
      },
    ];

    const handleWarehouseChanged = (warehouseId) => {
      if (warehouseId) {
        let res = state.warehouseList.find((item) => item.id === warehouseId);
        if (res) {
          state.warehouseInfo = res;
          state.productList = [];
          state.isKeepAllProductPackage = null;
          state.formState.fnSkuType = returnPlanFnskuTypeEnum.canScanInfo;
          state.formState.returnPlanDetails = [];
        } else {
          state.warehouseInfo = null;
        }
      } else {
        state.warehouseInfo = null;
      }
    };

    const getAddressInfo = () => {
      if (state.warehouseInfo) {
        return getAddressByLanguageV2(state.warehouseInfo, getters.language);
      }
      return "";
    };

    const handleShowModal = () => {
      if (!state.formState.receiveWarehouseId) {
        message.error(
          vueI18n.t("warehouse.please_select_the_receiving_warehouse_first")
        );
        return;
      }
      chooseProductModalRef.value.init();
    };

    const handleAddProduct = (record) => {
      // 获取可选服务
      const data = {
        receiveWarehouseId: state.formState.receiveWarehouseId,
        productIds: [record.id],
      };
      getServiceFeeConfig(data).then(({ result }) => {
        if (Array.isArray(result)) {
          result.map((item) => {
            if (item.feeType === orderLogisticsFeeEnum.QualityControl) {
              if (item.productId === record.id) {
                record.qualityControlFee = item;
                record.qualityControlFee.isActivited = false;
              }
            }
            if (item.feeType === orderLogisticsFeeEnum.FunctionQualityControl) {
              record.functionQualityControlFee = item;
              record.functionQualityControlFee.isActivited = false;
            }
            if (item.feeType === orderLogisticsFeeEnum.Destruction) {
              record.destructionFee = item;
            }
            if (item.feeType === orderLogisticsFeeEnum.ArtificialPerception) {
              record.artificialPerceptionFee = item;
            }
          });
          record.isKeepProductPackage = null;
          state.productList.push(record);
        }
      });
    };

    const handleRemoveProduct = (index) => {
      state.productList.splice(index, 1);
    };

    const handleQualityControlFeeChange = (record) => {
      if (typeof record === "object") {
        if (!record.qualityControlFee.isActivited) {
          record.functionQualityControlFee.isActivited = false;
        }
      }
    };

    const handlefnSkuChange = (record) => {
      let reg = /^[\w\+\-\$]+$/g
      if (state.formState.fnSkuType === returnPlanFnskuTypeEnum.canScanInfo) {
        if (!reg.test(record.fnSku)) {
          record.fnSku = null
          message.error(vueI18n.t('warehouse.scannable_information_error'))
        }
      }
    }

    const getTableError = (list) => {
      let fnSkuList = list.map((item) => item.fnSku);
      for (let i = 0; i < list.length; i++) {
        if (!list[i].fnSku) {
          currentPageIndex.value = parseInt(i / defaultPageSize.value) + 1;
          return vueI18n.t("common.p0_is_required", [
            vueI18n.t("warehouse.identifiable_information"),
          ]);
        }
        if (!list[i].count) {
          currentPageIndex.value = parseInt(i / defaultPageSize.value) + 1;
          return vueI18n.t("common.p0_is_required", [
            vueI18n.t("logistics.count"),
          ]);
        }
        if (list[i].isKeepProductPackage === null) {
          currentPageIndex.value = parseInt(i / defaultPageSize.value) + 1;
          return vueI18n.t("common.p0_is_required", [
            vueI18n.t("warehouse.whether_to_keep_the_outer_packaging"),
          ]);
        }
      }
      fnSkuList = [...new Set(fnSkuList)];
      if (fnSkuList.length < list.length) {
        return vueI18n.t("warehouse.p0_is_not_repeat", [
          vueI18n.t("warehouse.identifiable_information"),
        ]);
      }
    };

    const funcIsDestroyPackage=()=>{
      isDestroyPackage({...state.formState})
      .then(({result})=>{
        if(result&&result.destroyDays<=result.destroyScopeDays){
            Object.assign(destroyModal,result);
            destroyModal.visible=true;
        }else{
        getFee();
        }
      }).catch(()=>{
      })
    }

    const handleNextStep = async () => {
      try {
        await formRef.value.validate();
        if (!state.productList.length) {
          message.error(vueI18n.t("warehouse.please_adding_returned_products"));
          return;
        }
        let msg = getTableError(state.productList);
        if (msg) {
          message.error(msg);
          return;
        }
        currentPageIndex.value = 1;
        state.formState.returnPlanDetails = state.productList.map((item) => {
          return {
            productId: item.productId,
            fnSku: item.fnSku,
            count: item.count,
            isKeepProductPackage: item.isKeepProductPackage === 0 ? false : item.isKeepProductPackage === 1 ? true : null,
            optionService: {
              isFacadeQC:
                item.qualityControlFee && item.qualityControlFee.isActivited
                  ? true
                  : false,
              isFunctionQC:
                item.functionQualityControlFee &&
                item.functionQualityControlFee.isActivited
                  ? true
                  : false,
            },
          };
        });
         funcIsDestroyPackage();
        
      } catch (error) {}
    };

    const getFee = () => {
      state.btnLoading = true;
      const data = Object.assign({}, state.formState);
      getChannelReturnPlanFee(data)
        .then(({ result }) => {
          state.collectFees = result;
          state.pageConfirmState.destroyServiceFee = false;
          state.pageConfirmState.differentialCharges = false;
          state.pageStep = 1;
        })
        .finally(() => {
          state.btnLoading = false;
        });
    };

    const handleBack = () => {
      state.pageStep = 0;
    };

    const handleCancelPlan = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "consignment_return_channel_list" });
    };

    const handleConfirm = () => {
      state.payModal.visible = true;
      refPayment.value.handleShowPayOrder();
    };

    const handleOrderPay = () => {
      state.payModal.loading = true;
      const data = Object.assign({}, state.formState);
      data.payFee = filters.amountToFixed2(state.collectFees.amount);
      createChannelReturnPlan(data)
        .then(() => {
          message.success(vueI18n.t("common.create_success"));
         return checkTemporaryStoragePackage();
        })
        .catch(() => {
          state.payModal.loading = false;
        });
    };

   const  checkTemporaryStoragePackage=()=>{
        checkPackage(state.formState.packageNo,state.formState.receiveWarehouseId)
        .then(({result})=>{
              if (result&&result.amount>0) {
                          returnGoodHintModal.visible=true;
                          Object.assign(returnGoodHintModal,result);
                        } else {
                        handleCancelPlan();
                        }
                      state.payModal.visible = false;
                      state.payModal.loading = false;
        }).catch(() => {
          state.payModal.loading = false;
          state.payModal.visible = false;
        })
   }

   

    const handleGToKg = (val) => {
      if (!val) {
        return 0;
      }
      return gToKg(val);
    };

    const getWarehouseName = (id) => {
      if (id) {
        let result = state.warehouseList.find(item => item.id === id)
        if (result) {
          return result.warehouseNo
        }
      }
      return ''
    }

    const handleChangeProductPackage = () => {
      let res = state.isKeepAllProductPackage
      state.productList = state.productList.map(item => {
        item.isKeepProductPackage = res
        return item
      })
    }

    const handleTableOuterPackagingStatusChange = (record) => {
      let count = 0
      state.productList.map(item => {
        if (item.isKeepProductPackage === record.isKeepProductPackage) {
          count++
        }
      })
      if (count === state.productList.length) {
        state.isKeepAllProductPackage = record.isKeepProductPackage
      } else {
        state.isKeepAllProductPackage = null
      }
    }

    const getProductCount = computed(() => {
      return state.productList.reduce((total, item) => {
        return total + item.count;
      }, 0);
    });

    const getDestructionFee = computed(() => {
      let currencySymbol = "";
      let price = 0;
      state.productList.map((item) => {
        if (item.destructionFee) {
          if (!currencySymbol) {
            currencySymbol = item.destructionFee.currencySymbol;
          }
          if (!price) {
            price = item.destructionFee.price;
          }
        }
      }, 0);
      return currencySymbol + roundNumber(price);
    });

    const useQualityControlFee = () => {
      let result = false;
      state.productList.map((item) => {
        if (item.qualityControlFee && item.qualityControlFee.isActivited) {
          result = true;
        }
      });
      return result;
    };

    const disabledConfirmBtn = computed(() => {
      if (!state.pageConfirmState.differentialCharges) {
        return true;
      }
      if (useQualityControlFee()) {
        if (!state.pageConfirmState.destroyServiceFee) {
          return true;
        }
      }
      return false;
    });

    const disabledArtificialPerception = (item) => {
      if (item === returnPlanFnskuTypeEnum.artificialPerception) {
        if (state.productList.length) {
          let result = false
          state.productList.map(item => {
            if (!item.artificialPerceptionFee) {
              result = true
            }
          })
          return result
        }
        return true
      }
      return false
    }

    const getDetailById = (id) => {
      getReturnTemporaryStorageById({ id }).then(({ result }) => {
        if (result) {
          state.formState.sourceType = result.sourceType;
          state.formState.packageNo = result.packageNo;
          state.formState.packageNo1 = result.additionalIdentify;
          state.formState.receiveWarehouseId = result.warehouseId;
          if (state.warehouseList.length > 0) {
            handleWarehouseChanged(result.warehouseId);
          }
        }
      }).catch(() => {}).finally(() => {
      })
    }

    onMounted(async () => {
      if (!state.destroyRtsTemporaryDays) {
          state.beforeCreationHintModalVisible=true;
          getRtsConfig().then(({result})=>{
              state.destroyRtsTemporaryDays=result?.destroyRtsTemporaryDays;
          }).catch(()=>{
          })
      }

      try {
        let { result: warehouseList } = await getWarehouses({
          isActive: true,
          warehouseType: warehouseTypeEnum.consignmentWarehouse,
          isLinkManIncludeShopNo: true,
        });
        state.warehouseList = warehouseList;
        if (state.formState.receiveWarehouseId) {
          handleWarehouseChanged(state.formState.receiveWarehouseId);
        }
      } catch (error) {}

      nextTick(() => {
        state.formState.deliveryType = state.deliveryWayEnumList[0]
      })
    });

    watch(() => route.query, (val) => {
      if (Object.hasOwnProperty.call(val, 'id')) {
        let id = val.id;
        getDetailById(id);
      }
    }, { immediate: true, deep: true })

    return {
      ...toRefs(state),
      rules,
      columns,
      outerPackagingStatusList,
      formRef,
      chooseProductModalRef,
      returnPlanFnskuTypeEnum,
      orderLogisticsFeeEnum,
      refPayment,
      defaultPageSize,
      currentPageIndex,
      getProductCount,
      getDestructionFee,
      disabledConfirmBtn,
      returnGoodHintModal,
      destroyModal,
      disabledArtificialPerception,
      handleShowModal,
      handleAddProduct,
      handleRemoveProduct,
      handleQualityControlFeeChange,
      handleGToKg,
      handleNextStep,
      handleWarehouseChanged,
      getAddressInfo,
      handleBack,
      handleConfirm,
      handleOrderPay,
      useQualityControlFee,
      handlefnSkuChange,
      getWarehouseName,
      handleChangeProductPackage,
      handleTableOuterPackagingStatusChange,
      handleCancelPlan,
      getLinkman,
    };
  },
});
</script>
